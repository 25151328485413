import  client  from "socket.io-client";
import { urls } from "../const/urls";

const socket = client(urls);

export function useSocket () {
    
    return {
        socket
    }
}