import { url } from "../../const/urls";

export function createOrder(pars, callback) {
    fetch(url + '/createorder', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            //   mode: 'no-cors',
            body: JSON.stringify(pars)
        })
        .then((response) => response.json())
        .then((data) => {
            // console.log('data', data)
            
            return callback(data)
        });
}

export function parsePrice(pars, callback) {
    fetch(url + '/parseprice', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            //   mode: 'no-cors',
            body: JSON.stringify(pars)
        })
        .then((response) => response.json())
        .then((data) => {
            console.log('data parseprice', data)
            
            return callback(data)
        });
}


export function getUserQDeals(pars, callback) {
    fetch(url + '/getuserqdeals', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            //   mode: 'no-cors',
            body: JSON.stringify(pars)
        })
        .then((response) => response.json())
        .then((data) => {
            // console.log('data getuserqdeals', data)
            
            return callback(data)
        });
}


export function setActiveOrder(pars, callback) {
    fetch(url + '/setactiveorder', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(pars)
        })
        .then((response) => response.json())
        .then((data) => {
            // console.log('data setactiveorder', data)
            
            return callback(data)
        });
}

