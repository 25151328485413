import { svg_bep, svg_tron, svg_btc } from './svgs';

export const CURRENCY_LIST = [
    'USDT BEP20',
    'USDT TRC20',
    'BTC Bitcoin'
]

export const CURRENCY_LIST_SHORT = [
    'USDT',
    'USDT',
    'BTC'
]

export const CURRENCY_FIAT_LIST = [
    'RUB', 
    'USD',
    'IDR',
    'EUR',
    'UAH',
    'BYN',
    'KZT'
]

export const PRICE_TYPES = [
    'Фиксированная',
    'Плавающая'
]

export const TIME_LIMITS = [
    '15 мин',
    '1 час',
    '6 часов'
]

export const METHOD_PAY_LIST = [
    'Sber',
    'Raiff',
    'QIWI',
    'VTB'
]



export const list_svg_logos = [svg_bep, svg_tron, svg_btc]
export const list_token_names = ['USDT BEP20', 'USDT TRC20', 'BTC (bitcoin)']